export const dev = {
    auth0: {
        domain: 'dev-crew-time.eu.auth0.com',
        //vg clientId: 'QpDM7wQevEUddmZkEo8Li2HNKkSYv9Sb',
        clientId: 'PQzzOhpNXe1rBm9SEPhU69MrRZgX9jmt',
        audience: 'https://crew-time/api',
    }
}

export const prod = {
    auth0: {
        domain: 'dev-crew-time.eu.auth0.com',
        //vg clientId: 'QpDM7wQevEUddmZkEo8Li2HNKkSYv9Sb',
        clientId: 'PQzzOhpNXe1rBm9SEPhU69MrRZgX9jmt',
        audience: 'https://crew-time/api',
    }
}